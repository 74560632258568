import React, { useEffect, useState, useRef } from "react";
import TableComponent from "./tableComponent";
import moment from 'moment'
import ProformaModal from "./proformaModal";
import ProformaUpdateModal from "./proformaUpdateModal";
import {fetchAllClientsRequest} from "../Client/Duck/ClientsActions";
import {fetchAllProductsRequest} from "../Product/Duck/ProductsActions";
import {fetchAllProformaRequest, deleteProformaRequest} from "./Duck/ProformaActions";
import {connect} from "react-redux";
import TableContainer from "../Utils/TableContainer";
import Tippy from "@tippyjs/react";
import TextInput from "../Utils/TextInput";
import BaseModal from "../Utils/BaseModal";
import ReactToPrint from 'react-to-print';

const pageStyle = `
    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }

    @media print {
        .page-break {
            height: 25rem;
            display: block;
            page-break-before: auto;
        }
    }

    @page {
        size: auto;
        margin: 10mm;
    }
`;

class OrderProforma extends React.Component {
    state = {
        headingData: [
            // "S. No.",
            "Proforma No",
            "Client Name",
            // "Billing to",
            "Date",
            "Proforma Amount",
            "Advance Amount",
            "Pending Amount",
            "GST Amount",
            "Actions"
        ],
        showProformaModal: false,
        previewProformaModal: false,
        proformaId: null,
        screenHeight: 455,
        editProformaModal: false,
        removeModal: false,
        showToggleUserStatusModal: false,
        searchText: "",
        isLoading: false
    }
    handleHeight = (e) => {
        this.setState({screenHeight: e.target.value})
    }

    handleModal = (show = false, show2 = false, proformaId = null, edit = false, remove = false) => {
        this.setState({
            showProformaModal: show,
            previewProformaModal: show2,
            proformaId: proformaId,
            editProformaModal: edit,
            removeModal: remove
        });
    };

    componentDidMount() {
        let { client, fetchClient, fetchProduct, fetchProforma } = this.props;
            fetchClient();
            fetchProduct();
            fetchProforma();
    }
    onSearch = (text = "") => {
        this.setState({
            searchText: text.trim(),
        });
    };

    deleteProforma = () => {
        let {loggedInUser, deleteProforma} = this.props;
        const isAdmin = loggedInUser && loggedInUser.role_id === "admin";
        if(isAdmin){
            let {proformaId} = this.state;
            this.setState({isLoading: true});
            deleteProforma(proformaId)
            setTimeout(()=>{
                this.setState({isLoading: false})
                this.handleModal();
            }, 2000)
        }

    }

    renderFooter = () => {

        return (
            <>
                <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => this.handleModal()}
                >
                    No
                </button>

                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.deleteProforma}
                    disabled={this.state.isLoading}
                >
                    {
                        this.state.isLoading
                            ? (
                                <>
                                    <span className="spinner-border spinner-border-sm"></span>
                                    <span className="visually-hidden"> Deleting...</span>
                                </>
                            )
                            : "Yes"
                    }
                </button>
            </>
        );
    };

    getFilterUserOrder = () => {
        let { searchText } = this.state;
        let { proforma } = this.props;

        let data = proforma && Object.values(proforma)
        if (searchText) {
            data = data.filter(o=> o && o.shipping_address.name.toLowerCase().includes(searchText.toLowerCase()))
        }

        return data || [];
    }

    renderRowItem = (item, index) => {
        // let amount = Object.values(item?.items).reduce((accumulator, currentValue)=>accumulator + (currentValue.rate * Number(currentValue.qty)), 0)
        // amount = (amount + Number(item?.packing || 0) + Number(item?.insurance || 0) + Number(item?.freight || 0))  - Number(item?.discount || 0)
        // let grandTotal = parseFloat((amount * 18 / 100) + amount).toFixed(2)
        let color;
        switch (item?.status) {
            case 'pending':
                color = 'orange'
                break;
            case 'completed':
                color = 'green'
                break;
            case 'rejected':
                color = 'red'
                break;
        }
        let pending_amount = (item && item.total_amount) ? Number(item.total_amount- (item.paid_amount ? item.paid_amount : 0)) : 0
        const textDecoration = color === 'red' ? {textDecoration: "line-through"} : {}
        console.log("-------",item)
        return (
            <tr key={item?._id} style={{...textDecoration, color}}>
                <td className={'text-center'} onClick={()=>this.handleModal(false, true, item?._id)}>{item?.proforma_number}</td>
                <td onClick={()=>this.handleModal(false, true, item?._id)}>{item?.shipping_address.name}</td>
                <td className={'text-center'} onClick={()=>this.handleModal(false, true, item?._id)}>{moment(item?.proformaDate).format('DD-MMM-YYYY')}</td>
                <td className={'text-center'} style={{ width: "10%" }} onClick={()=>this.handleModal(false, true, item?._id)}>₹ {item?.total_amount}</td>
                <td className={'text-center'} style={{ width: "10%"}} onClick={()=>this.handleModal(false, true, item?._id)}>₹ {item?.paid_amount}</td>
                <td className={'text-center'} style={{ width: "10%"}} onClick={()=>this.handleModal(false, true, item?._id)}>₹ {pending_amount}</td>
                <td className={'text-center'} style={{color}} onClick={()=>this.handleModal(false, true, item?._id)}>{item?.gst_amount}</td>
                <td className={'text-center'}>
                    {/*<span onClick={()=>this.handleModal(false, true, item?._id)}>*/}
                    {/*   <Tippy content="Preview">*/}
                    {/*        <i className="bx bxs-printer"></i>*/}
                    {/*    </Tippy>*/}
                    {/*</span>*/}
                    <span className={'ml-2 z-5'} onClick={()=>this.handleModal(false, false, item?._id, true)}>
                       <Tippy content="Edit">
                            <i className="bx bxs-edit"/>
                        </Tippy>
                    </span>
                    <span className={'ml-2 z-5'} onClick={() => this.handleModal(false, false, item?._id, false, true)}>
                       <Tippy content="Delete">
                            <i className="fe fe-delete"/>
                        </Tippy>
                    </span>

                </td>
            </tr>
        );
    };

    render() {
        let { showProformaModal, previewProformaModal, proformaId, screenHeight, editProformaModal, removeModal  } = this.state;
        // let proforma = this.props.proforma && (Object.keys(this.props.proforma).length > 0 ? Object.values(this.props.proforma) : []);
        let proforma = this.getFilterUserOrder();
        let totalCount = proforma?.length
console.log("-------------",previewProformaModal, proforma)
        return (
            <>

                <div className="row">
                    <div className="col-md-12">
                        {previewProformaModal ? <div className="page-title-box d-flex align-items-center justify-content-between">
                                <div className="page-title-left">
                                    <button
                                        type="button"
                                        className="btn btn-primary my-2 btn-icon-text"
                                        onClick={() => this.handleModal(false, false, null)}
                                    >
                                        <i className="fe fe-arrow-left mr-2"></i> Back
                                    </button>
                                </div>
                                <TextInput value={screenHeight} onChange={this.handleHeight}/>
                                <div className="page-title-right">
                                    <ReactToPrint
                                        pageStyle={pageStyle}
                                        trigger={() => (
                                            <button
                                                className="btn btn-primary my-2 btn-icon-text"
                                                type="button"
                                            >
                                                <i className="dripicons dripicons-print mr-2" />
                                                Print
                                            </button>
                                        )}
                                        // documentTitle={`Manifest - ${moment().format('DD-MMM-YY')}`}
                                        content={() => this.componentRef}
                                    />

                                </div>
                            </div> :
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">
                                        Proforma List
                                    </h2>
                                </div>
                            </div>
                            <div className="page-title-right">
                                <button
                                    type="button"
                                    className="btn btn-primary my-2 btn-icon-text"
                                    onClick={() => this.handleModal(true)}
                                >
                                    <i className="fe fe-plus mr-2"></i> Generate Proforma
                                </button>
                            </div>
                        </div>}
                    </div>
                </div>


                {previewProformaModal &&
                    <TableComponent ref={el => (this.componentRef = el)} screenHeight={screenHeight} proforma={this.props.proforma[proformaId]}/>
                }
                {
                    !previewProformaModal &&
                        <TableContainer
                            title={"Proforma"}
                            rowData={proforma ? proforma : []}
                            renderRow={this.renderRowItem}
                            filter={{ searchText: this.state.searchText }}
                            onSearch={this.onSearch}
                            searchPlaceholder={'Search by clients'}
                            totalEntries={totalCount}
                            showFilter={true}
                            filterOption={["All", "Pending", "Completed", "Rejected"]}
                            headings={this.state.headingData}/>
                }
                <ProformaModal
                    // proformaId={proformaId}
                    show={showProformaModal}
                    handelModal={this.handleModal}
                />
                <ProformaUpdateModal
                    key={proformaId}
                    proformaId={proformaId}
                    show={editProformaModal}
                    handelModal={this.handleModal}
                />
                <BaseModal
                    closeButton={false}
                    title={"Delete Proforma"}
                    show={removeModal}
                    size={'md'}
                    footerComponent={this.renderFooter}
                >
                    Are you sure to delete this <b>{proforma?.find(o=> o && o._id ===proformaId)?.proforma_number} </b>?
                </BaseModal>




            </>
        );
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        client: state?.client?.clients,
        product: state?.product?.products,
        proforma: state?.proforma?.proforma,
        loading: state?.client?.loading,
        error: state?.client?.error,
        loggedInUser: state?.loggedInUser?.data?.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchClient: (params) => dispatch(fetchAllClientsRequest(params)),
        fetchProduct: (params) => dispatch(fetchAllProductsRequest(params)),
        fetchProforma: (params) => dispatch(fetchAllProformaRequest(params)),
        deleteProforma: (params) => dispatch(deleteProformaRequest(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderProforma);

