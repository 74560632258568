import { put, takeLatest, call, fork } from "redux-saga/effects";
import APP_URL from "../../Constants/AppUrl";
import ACTIONS from "./ChallanActionsType";
import axios from "axios";
import { showNotification } from "../../Utils/CommonFunctions";

const createChallanApi = (data) => {
    return axios({
        method: "POST",
        url: APP_URL.CHALLAN_URL,
        data: data,
    });
};

function* createChallan(action) {
    try {
        let response = yield call(createChallanApi, action.payload);
        let resData = response?.data;
        if (resData?.success === false) {
            showNotification("error", resData?.message || resData?.message);
            yield put({
                type: ACTIONS.CREATE_FAIL,
                payload: resData,
            });
        } else {
            showNotification("success", resData?.message);
            yield put({
                type: ACTIONS.CREATE_SUCCESS,
                challan: resData.challan,
            });
        }
    } catch (error) {
        console.log("Create Challan ", error);
    }
}

const fetchAllChallansApi = (params) => {
    return axios({
        method: "GET",
        url: APP_URL.CHALLAN_URL,
        params: params,
    });
};

function* fetchAllChallans(action) {
    try {
        let response = yield call(fetchAllChallansApi, action.payload);
        let resData = response?.data;
        if (resData?.success === false) {
            showNotification("error", resData?.message);
            yield put({
                type: ACTIONS.FETCH_ALL_FAIL,
                payload: resData,
            });
        } else {
            let payload = resData || {};
            payload.isReset = action?.payload?.offset === 0;
            yield put({
                type: ACTIONS.FETCH_ALL_SUCCESS,
                payload: payload,
            });
        }
    } catch (error) {
        console.log("Fetch Challans ", error);
    }
}


const updateChallanApi = (data) => {
    return axios({
        method: "PUT",
        url: `${APP_URL.CHALLAN_URL}/${data?._id}`,
        data: data,
    });
};

function* updateChallan(action) {
    try {
        let response = yield call(updateChallanApi, action.payload);
        let resData = response?.data;
        console.log(resData, 'ChallanRes')
        if (resData?.success === false) {
            showNotification("error", resData?.message || resData?.message);
            yield put({
                type: ACTIONS.UPDATE_FAIL,
                payload: resData,
            });
        } else {
            showNotification("success", resData?.message);
            yield put({
                type: ACTIONS.UPDATE_SUCCESS,
                challan: resData?.challan,
            });
        }
    } catch (error) {
        console.log("Update Challan ", error);
    }
}

const deleteChallanApi = (id) => {
    return axios({
        method: "DELETE",
        url: `${APP_URL.CHALLAN_URL}/${id}`,
    });
};

function* deleteChallan(action) {
    try {
        let response = yield call(deleteChallanApi, action.id);
        let resData = response?.data;

        if (resData?.success === false) {
            yield put({
                type: ACTIONS.DELETE_FAIL,
                payload: resData,
            });
        } else {
            showNotification("success", resData?.message);
            yield put({
                type: ACTIONS.DELETE_SUCCESS,
                id: action.id,
            });
        }
    } catch (error) {
        console.log("Delete Challan ", error);
    }
}

function* watchChallans() {
    yield takeLatest(ACTIONS.CREATE_REQUEST, createChallan);
    yield takeLatest(ACTIONS.FETCH_ALL_REQUEST, fetchAllChallans);
    yield takeLatest(ACTIONS.UPDATE_REQUEST, updateChallan);
    yield takeLatest(ACTIONS.DELETE_REQUEST, deleteChallan);

}

// ACTION WATCHER
export default function* ChallanSaga() {
    yield fork(watchChallans);
}
