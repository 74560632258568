import React, { useEffect, useState, useRef } from "react";
import moment from 'moment'
import {fetchAllClientsRequest} from "../Client/Duck/ClientsActions";
import {fetchAllProductsRequest} from "../Product/Duck/ProductsActions";

import {connect} from "react-redux";
import Tippy from "@tippyjs/react";
import Table from "../Utils/TableContainer";
import BaseModal from "../Utils/BaseModal";
import ReactToPrint from 'react-to-print';
import * as XLSX from 'xlsx';
import {deleteInvoiceRequest, fetchAllInvoiceRequest} from "../Invoice/Duck/InvoiceActions";

const pageStyle = `
    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }

    @media print {
        .page-break {
            height: 25rem;
            display: block;
            page-break-before: auto;
        }
    }

    @page {
        size: auto;
        margin: 10mm;
    }
`;

class ClientLedger extends React.Component {
    state = {
        heading: [
            "Date",
            "Description",
            "Reference",
            "Debit",
            "Credit",
            "Balance"
        ],
        gl: [
        {
            date:"25-Jan-2024",
            description: "Items val",
            reference: "Cash",
            debit: 10000,
            credit: 5000,
            balance: 5000
        },
            {
                date:"25-Jan-2024",
                description: "Items val",
                reference: "Cash",
                debit: 10000,
                credit: 5000,
                balance: 5000
            },
            {
                date:"25-Jan-2024",
                description: "Items val",
                reference: "Cash",
                debit: 10000,
                credit: 5000,
                balance: 5000
            }
        ]
    }

    renderRowItem = (item, index) => {
        return (
            <tr key={index}>
                <td style={{width: '10%', textAlign:'center'}}>{item?.date}</td>
                <td style={{width: '40%'}}>{item?.description}</td>
                <td style={{width: '20%'}}>{item?.reference}</td>
                <td style={{width: '10%', textAlign:'center'}}>{parseFloat((item?.debit) || 0).toFixed(2)}</td>
                <td style={{width: '10%'}}>{parseFloat((item?.credit) || 0).toFixed(2)}</td>
                <td style={{width: '10%'}}>{parseFloat((item?.balance) || 0).toFixed(2)}</td>
            </tr>
        );
    };
    render(){
        const {heading, gl} = this.state;
        return(
            <div className="col-md-12">
                <ReactToPrint
                    pageStyle={pageStyle}
                    trigger={() => (
                        <button
                            className="btn btn-primary my-2 btn-icon-text"
                            type="button"
                        >
                            <i className="dripicons dripicons-print mr-2" />
                            Print
                        </button>
                    )}
                    // documentTitle={`Manifest - ${moment().format('DD-MMM-YY')}`}
                    content={() => this.componentRef}
                />
                <div ref={el => (this.componentRef = el)} className="col-md-12">
                    <div className="row p-1">
                        <div className="col-md-9">
                        <p style={{fontSize: "15px"}}><b>Name: </b>M/s Apollo Hospitals North Limited</p>
                        <p style={{fontSize: "15px"}}><b>Address: </b>#55, Ali Tower III Floor, Greams Road, Chennai - 600 006</p>
                        <p style={{fontSize: "15px"}}><b>GST: </b>33AAICA1080F1Z5</p>
                        </div>
                        <div className="col-md-3">
                            <p style={{fontSize: "15px"}}><b>Opening Balance: </b>{1000.00}</p>
                            <p style={{fontSize: "15px"}}><b>Closing Balance: </b>{5000.00}</p>
                        </div>
                    </div>
                    <div className="card-body p-1">
                        <Table rowData={gl}
                               showSearch={false}
                               showPagination={false}
                               renderRow={this.renderRowItem}
                               headings={heading} />;
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        client: state?.client?.clients,
        product: state?.product?.products,
        invoice: state?.invoice?.invoice,
        loading: state?.client?.loading,
        error: state?.client?.error,
        loggedInUser: state?.loggedInUser?.data?.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchClient: (params) => dispatch(fetchAllClientsRequest(params)),
        fetchProduct: (params) => dispatch(fetchAllProductsRequest(params)),
        fetchInvoice: (params) => dispatch(fetchAllInvoiceRequest(params)),
        deleteInvoice: (params) => dispatch(deleteInvoiceRequest(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLedger);
