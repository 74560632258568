const ACTIONS = {
    CREATE_REQUEST: "CREATE_PROFORMA_REQUEST",
    CREATE_SUCCESS: "CREATE_PROFORMA_SUCCESS",
    CREATE_FAIL: "CREATE_PROFORMA_FAIL",

    FETCH_REQUEST: "FETCH_PROFORMA_REQUEST",
    FETCH_SUCCESS: "FETCH_PROFORMA_SUCCESS",
    FETCH_FAIL: "FETCH_PROFORMA_FAIL",

    FETCH_ALL_REQUEST: "FETCH_ALL_PROFORMAS_REQUEST",
    FETCH_ALL_SUCCESS: "FETCH_ALL_PROFORMAS_SUCCESS",
    FETCH_ALL_FAIL: "FETCH_ALL_PROFORMAS_FAIL",

    UPDATE_REQUEST: "UPDATE_PROFORMA_REQUEST",
    UPDATE_SUCCESS: "UPDATE_PROFORMA_SUCCESS",
    UPDATE_FAIL: "UPDATE_PROFORMA_FAIL",

    DELETE_REQUEST: "DELETE_PROFORMA_REQUEST",
    DELETE_SUCCESS: "DELETE_PROFORMA_SUCCESS",
    DELETE_FAIL: "DELETE_PROFORMA_FAIL",

};

export default ACTIONS;
