import React from 'react';
import WebBase from "./WebLayout/WebBase";

const Services = () => {
    return (
        <>
            <WebBase>
                <section className="career-pg">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12 pt-5 pb-5">
                                  <p>
                                      Our services include pre-planning due diligence, budget estimates, feasibility studies, as well as design and construction coordination
                                       </p>
                                <ul>
                                      <li>Overhead Transmission System</li>
                                      <li>Construction, Erection, Testing & Commissioning of all types of High Voltage overhead transmission lines and structures of 11 KV & above</li>
                                      <li>Structure and foundation design</li>
                                      <li>Planning for Line routing</li>
                                      <li>Underground Transmission System</li>
                                      <li>Construction, Cable-laying and Energisation of all types of transmission lines</li>
                                      <li>Cable system design including analysis and calculation for cable support and pressure maintenance systems</li>
                                      <li>Duct work involving Trenching, Directional Boring and Manholes</li>
                                      <li>High voltage power cable and accessories supply</li>
                                      <li>Electrical Domain Services Snapshot</li>
                                      <li>HT/LT Power distribution</li>
                                      <li>HV works of OH cum U/G up to 11 KV and above</li>
                                      <li>PCC and MCC operation</li>
                                      <li>Operations and maintenance of chillers</li>
                                      <li>AHU handling</li>
                                      <li>Precession air conditioning</li>
                                      <li>Fire fighting and smoke detection system</li>
                                      <li>Main L.T. Panels & Distribution boards</li>
                                      <li>DG sets & associated accessories</li>
                     </ul>
                                  <br/>
                                  <br/>
                              </div>
                          </div>
                      </div>
                  </section>
            </WebBase>
        </>
    )
}

export default Services